import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'
import bank from './routes/bank'
import users from './routes/users'
import roles from './routes/roles'
import email from './routes/email'
import retrocession from './routes/retrocession'
import clients from './routes/clients'
import documents from './routes/documents'
import settings from './routes/settings'
import todolist from './routes/todolist'
import clientusers from './routes/clientusers'
import meetings from './routes/meetings'
// import { email } from 'vee-validate/dist/rules'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    ...retrocession,
    ...email,
    ...pages,
    ...bank,
    ...users,
    ...roles,
    ...clients,
    ...documents,
    ...settings,
    ...todolist,
    ...clientusers,
    ...meetings
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // 如果是登录页面
  if (to.name === 'auth-login') {
    if (isLoggedIn) {
      return next(getHomeRouteForLoggedInUser())
    }
    return next()
  }

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login' })
    return next({ name: 'not-authorized' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
import Vue from 'vue'
import router from '@/router'
import Swal from 'sweetalert2'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
   baseURL: process.env.VUE_APP_SERVICE_URL,
  // timeout: 1000,
   headers: {'Authorization': 'Bearer '+localStorage.getItem('accessToken')}
})

// Add a response interceptor
axiosIns.interceptors.response.use( (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response dat
  return response;
},  (error) =>  {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if(error.response.status == 401){
    localStorage.clear();
    console.log('401 Unauthorized')

    router.replace('/login').then(() => {
      Swal.fire({
        text: "you have been logged out!",
        confirmButtonText: "OK",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
    });
  }

  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns

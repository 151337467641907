export default [
  {
    path: "/settings/document/document_type/list",
    name: "setting-document-documenttype",
    component: () =>
      import(
        "@/views/setting/document/documenttype/documenttypes-list/DocumentTypesList.vue"
      ),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
  {
    path: "/settings/document/document_category/list",
    name: "setting-document-documentcategory",
    component: () =>
      import(
        "@/views/setting/document/documentcategory/documentcategories-list/DocumentCategoriesList.vue"
      ),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
  {
    path: "/settings/course/coursesetting/list",
    name: "setting-coursesetting-list",
    component: () =>
      import("@/views/setting/course/course/coursesetting-list/CourseSettingList.vue"),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
  {
    path: "/settings/course/organiser/list",
    name: "setting-organiser-list",
    component: () =>
      import("@/views/setting/course/organiser/organiser-list/OrganiserList.vue"),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
  {
    path: "/settings/accounttype/list",
    name: "setting-account-type-list",
    component: () =>
      import("@/views/setting/accounttype/accounttype-list/AccountTypeList.vue"),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
  {
    path: '/email-template',
    name: 'email-template',
    component: () => import('@/views/email/email-list/EmailList.vue'), meta: {
      action: 'read',
      resource: 'setting',
    },
  },
  {
    path: "/settings/persontype/list",
    name: "setting-person-type-list",
    component: () =>
      import("@/views/setting/persontype/accounttype-list/AccountTypeList.vue"),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
  {
    path: "/settings/nationality/list",
    name: "setting-nationality-list",
    component: () =>
      import("@/views/setting/nationality/nationality-list/NationalityList.vue"),
    meta: {
      action: "read",
      resource: "setting",
    },
  },
];

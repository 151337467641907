import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/option/list`, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error));
      })
    },
    fetchDashboardInfo(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/info')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardStaff(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/staffCount')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardClient(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/clientCount')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardBanks(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/getBanks')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchAnnualReturn(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/annualReturn')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardCourse(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/getCourse')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardCourseForCPD(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/getCourseCpd')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardToDo(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/dashboard/todoActions')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchUserAuth(ctx, queryParams){
      return new Promise((resolve, reject) => {
        axiosIns.get('/auth', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error))
      })
    }

  },
}
